export const clientNameFooter = "BIA ROSOLOINO YOGA";
export const titleMain = "Namastê! Seja bem-vinde :)";
export const subtitleMain = "Yoga é união. União que transcende espaço e tempo. Junte-se a nós. No seu ritmo, onde você estiver.";

export const aspectRatioLogoAbout = '3'; // width/height
export const aspectRatioLogoMain = '3'; // width/height

export const borderRadiusButtons = '30px';

export const nomeFornecedorCompleto = "BIA ROSOLINO YOGA";
export const nomeFornecedor = "BIA ROSOLINO YOGA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/biarosolinoyoga/",
    profile: "@biarosolinoyoga"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send/?phone=5521997322245&text&type=phone_number&app_absent=0",
    number: "(21) 99732-2245"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send/?phone=5521997322245&text&type=phone_number&app_absent=0",
  },
  {
    title: "Ficha de Anamnese",
    link: "https://docs.google.com/forms/d/10gTPK126XL8VAqiNcDRYWedyJ4he3y0tAat1d0PmUSw/edit?usp=drivesdk",
  }
];

export const linkAppleStore = "https://play.google.com/store/apps/details?id=app.web.mobile_bia_rosolino.twa"
export const linkPlayStore = "https://apps.apple.com/us/app/bia-rosolino-yoga/id1641100667"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];